define("discourse/plugins/swapd-trustap/discourse/templates/connectors/user-preferences-security/swapd-trustap", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <TrustapConnectButton @user={{this.model}}/>
  */
  {
    "id": "A9RpKJAF",
    "block": "[[[8,[39,0],null,[[\"@user\"],[[30,0,[\"model\"]]]],null]],[],false,[\"trustap-connect-button\"]]",
    "moduleName": "discourse/plugins/swapd-trustap/discourse/templates/connectors/user-preferences-security/swapd-trustap.hbs",
    "isStrictMode": false
  });
});