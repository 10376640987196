define("discourse/plugins/swapd-trustap/discourse/components/trustap-connect-button", ["exports", "@glimmer/component", "discourse-common/helpers/d-icon", "@ember/object", "discourse/components/d-button", "@ember/service", "discourse-common/helpers/i18n", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory", "@ember/component"], function (_exports, _component, _dIcon, _object, _dButton, _service, _i18n, _ajax, _ajaxError, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let TrustapConnectButton = _exports.default = (_class = (_class2 = class TrustapConnectButton extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
    }
    get trustapAccount() {
      return this.args.user.trustap_id || '-';
    }
    get showConnectButton() {
      // don't show if you're an admin looking at someone else
      if (this.args.user.id != this.currentUser.id) {
        return false;
      }
      let trustap_id1 = this.args.user.trustap_id;
      // do show if no id
      if (trustap_id1 === null || trustap_id1 === '') {
        return true;
      }
      // do show if guest
      return trustap_id1.startsWith('1-');
    }
    get trustapAccountTypeAsString() {
      let trustap_id1 = this.args.user.trustap_id || null;
      if (trustap_id1 === null || trustap_id1 === '') {
        return '';
      }
      if (trustap_id1.startsWith('1-')) {
        return '(Guest User)';
      } else {
        return '(Full User)';
      }
    }
    get showRemoveAccountButton() {
      return this.siteSettings.swapd_trustap_enable_test_functions && this.args.user.trustap_id;
    }
    startOpenID() {
      window.location.href = '/trustap/openid-start';
    }
    async removeAccount() {
      let result1 = await (0, _ajax.ajax)(`/trustap/remove-account`, {
        type: "POST",
        data: {
          target_user_id: this.args.user.id
        }
      }).then(data1 => {
        alert("Account removed");
        window.location.reload();
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        //
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="control-group pref-trustap">
        <label class="control-label">TrustAP</label>
        Account ID: <code>{{this.trustapAccount}}</code> {{ this.trustapAccountTypeAsString }}
        {{#if this.showConnectButton}}
          <DButton
            @action={{this.startOpenID}}
            @label="trustap.connect_with_trustap"
          />
          <div class="instructions">{{i18n "trustap.convert_to_full_user"}}</div>
        {{/if}}
        {{#if this.showRemoveAccountButton}}
            <DButton
              @action={{this.removeAccount}}
              @label="trustap.remove_account_button"
            />
          {{/if}}
      </div>
    
  */
  {
    "id": "sWprmmdM",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"control-group pref-trustap\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"TrustAP\"],[13],[1,\"\\n      Account ID: \"],[10,\"code\"],[12],[1,[30,0,[\"trustapAccount\"]]],[13],[1,\" \"],[1,[30,0,[\"trustapAccountTypeAsString\"]]],[1,\"\\n\"],[41,[30,0,[\"showConnectButton\"]],[[[1,\"        \"],[8,[32,0],null,[[\"@action\",\"@label\"],[[30,0,[\"startOpenID\"]],\"trustap.connect_with_trustap\"]],null],[1,\"\\n        \"],[10,0],[14,0,\"instructions\"],[12],[1,[28,[32,1],[\"trustap.convert_to_full_user\"],null]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showRemoveAccountButton\"]],[[[1,\"          \"],[8,[32,0],null,[[\"@action\",\"@label\"],[[30,0,[\"removeAccount\"]],\"trustap.remove_account_button\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/swapd-trustap/discourse/components/trustap-connect-button.js",
    "scope": () => [_dButton.default, _i18n.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "startOpenID", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "startOpenID"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAccount", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeAccount"), _class.prototype)), _class);
});