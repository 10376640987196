define("discourse/plugins/swapd-trustap/discourse/components/modal/trustap-ask-reason", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/components/d-button", "discourse/components/d-modal", "discourse/components/preference-checkbox", "discourse-i18n", "@ember/component", "discourse/components/d-textarea", "discourse-common/helpers/i18n", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _dButton, _dModal, _preferenceCheckbox, _discourseI18n, _component2, _dTextarea, _i18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let TrustapAskReason = _exports.default = (_class = (_class2 = class TrustapAskReason extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "reasonText", _descriptor, this);
    }
    get modalTitle() {
      return this.args.model.title;
    }
    get modalText() {
      return this.args.model.text;
    }
    get modalButton() {
      return this.args.model.button;
    }
    get cancelButton() {
      return this.args.model.cancel_button;
    }
    get actionDisabled() {
      return this.reasonText.length < 5;
    }
    performAction() {
      this.args.model.actionCallback({
        reasonText: this.reasonText
      });
      this.args.closeModal();
    }
    cancelAction() {
      this.args.closeModal();
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal
        @closeModal={{@closeModal}}
        @title={{this.modalTitle}}
        @inline={{@inline}}
      >
        <:body>
          <p>
            {{this.modalText}}
          </p>
            <Textarea
              id="trustap-ask-reason"
              @value={{this.reasonText}}
            />
        </:body>
        <:footer>
          <DButton
            @action={{this.performAction}}
            @icon="check"
            @label={{this.modalButton}}
            @disabled={{this.actionDisabled}}
            id="trustap-ask-reason-confirm"
            class="btn-primary"
          />
                  <DButton
            @action={{this.cancelAction}}
            @icon="arrow-left"
            @label={{this.cancelButton}}
            id="trustap-ask-reason-cancel"
          />
        </:footer>
      </DModal>
    
  */
  {
    "id": "ZBfznMJV",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@closeModal\",\"@title\",\"@inline\"],[[30,1],[30,0,[\"modalTitle\"]],[30,2]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[30,0,[\"modalText\"]]],[1,\"\\n        \"],[13],[1,\"\\n          \"],[8,[32,1],[[24,1,\"trustap-ask-reason\"]],[[\"@value\"],[[30,0,[\"reasonText\"]]]],null],[1,\"      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],[[24,1,\"trustap-ask-reason-confirm\"],[24,0,\"btn-primary\"]],[[\"@action\",\"@icon\",\"@label\",\"@disabled\"],[[30,0,[\"performAction\"]],\"check\",[30,0,[\"modalButton\"]],[30,0,[\"actionDisabled\"]]]],null],[1,\"\\n                \"],[8,[32,2],[[24,1,\"trustap-ask-reason-cancel\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"cancelAction\"]],\"arrow-left\",[30,0,[\"cancelButton\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@inline\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/swapd-trustap/discourse/components/modal/trustap-ask-reason.js",
    "scope": () => [_dModal.default, _dTextarea.default, _dButton.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "reasonText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "performAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "performAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelAction"), _class.prototype)), _class);
});